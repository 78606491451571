import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GuideServicesAndEventsModule } from '@app/screens/guide/guide-services-and-events/guide-services-and-events.module';
import { ProgramModule } from '@app/modules/program/program.module';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '@app/shared';
import { RouterModule } from '@angular/router';

import { QuizItemComponent } from './components/quiz-item/quiz-item.component';
import { SurveysComponent } from './components/surveys/surveys.component';
import { TranslocoRootModule } from '@libs/transloco';

@NgModule({
  imports: [
    CommonModule,
    GuideServicesAndEventsModule,
    ProgramModule,
    ReactiveFormsModule,
    SharedModule,
    RouterModule,
    TranslocoRootModule
  ],
  declarations: [QuizItemComponent, SurveysComponent]
})
export class SurveysModule {}
