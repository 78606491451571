import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UserRoles } from '@app/shared/enums/user-roles';
import { AbstractNavigationEntriesService } from '@app/modules/navigation/abstracts';
import { INavigationEntry } from '@app/modules/navigation/interfaces';

@Injectable()
export class NavigationEntriesGuideService extends AbstractNavigationEntriesService {
  role = UserRoles.GUIDE;

  entries: Observable<INavigationEntry>[] = [
    // widget - primary
    this.navigationEntryFactory.guideCalendar$(this.dependencies),
    this.navigationEntryFactory.guideInbox$(this.dependencies),
    this.navigationEntryFactory.guideClients$(),
    this.navigationEntryFactory.guideSecureMessaging$(this.dependencies),
    this.navigationEntryFactory.legacyGuideServices$(),
    this.navigationEntryFactory.guideServices$(),
    this.navigationEntryFactory.guideForms$(),
    this.navigationEntryFactory.guidePayments$(),
    this.navigationEntryFactory.guideReports$(),
    this.navigationEntryFactory.guideBlogs$(),
    this.navigationEntryFactory.guideCommunity$(),

    // widget - secondary
    this.navigationEntryFactory.guideAccountAndSettings$(),
    this.navigationEntryFactory.whatsNew$(this.dependencies),

    // widget - workspace
    this.navigationEntryFactory.guideSettingsTeamSettings$(),
    this.navigationEntryFactory.guideSettingsTeamMembers$(),
    this.navigationEntryFactory.guideTeamPermissions$(),
    this.navigationEntryFactory.settingsGuidePublicPage$(),
    this.navigationEntryFactory.guideSettingsSubscription$(),
    this.navigationEntryFactory.settingsGuideBranding$(),

    // widget - profile
    this.navigationEntryFactory.guideSettingsMyAccount$(),
    this.navigationEntryFactory.guideSettingsEarnAffiliateRewards$(),

    // widget - links
    this.navigationEntryFactory.urlCancellationPolicy$(this.dependencies),
    this.navigationEntryFactory.urlPrivacy$(this.dependencies),
    this.navigationEntryFactory.urlTerms$(this.dependencies),
    this.navigationEntryFactory.emailHelp$(this.dependencies),
    this.navigationEntryFactory.urlVisitHelpCenter$(this.dependencies),
    this.navigationEntryFactory.guideSettingsSignupAsClient$(this.dependencies)
  ];
}
