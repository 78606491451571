import { Observable } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AbsWorkspaceService } from '@app/modules/workspaces/services/abs-workspace.service';
import {
  IWorkspace,
  IWorkspaceClient,
  IWorkspaceInvitationInfo,
  IWorkspaceMember,
  IWorkspaceSettingsData,
  WorkspaceRoute,
  WorkspaceUser
} from '@app/modules/workspaces/types';
import { PUBLIC_WORKSPACE_ENDPOINT } from '@app/shared/constants/endpoints';
import { IServiceGuide } from '@app/shared/interfaces/services';

@Injectable({ providedIn: 'root' })
export class WorkspacesServerStoreService extends AbsWorkspaceService {
  constructor(_http: HttpClient) {
    super(_http, WorkspaceRoute.GUIDE);
  }

  getWorkspaces$(): Observable<IWorkspace[]> {
    return this._http.get<IWorkspace[]>(this.API_ENDPOINT);
  }

  // todo add type to return value
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  createWorkspace$(name: string, type: string): Observable<any> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return this._http.post<any>(this.API_ENDPOINT, { name, type });
  }

  getActiveWorkspace$(): Observable<{ workspace: IWorkspace }> {
    return this._http.get<{ workspace: IWorkspace }>(`${this.API_ENDPOINT}/active`);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  sendInvitations$(invitationsInfo: IWorkspaceInvitationInfo): Observable<any> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return this._http.post<any>(`${this.API_ENDPOINT}/invite`, { ...invitationsInfo });
  }

  getWorkspaceUsers$(): Observable<WorkspaceUser[]> {
    return this._http.get<WorkspaceUser[]>(`${this.API_ENDPOINT}/users`);
  }

  getMembers$(): Observable<IWorkspaceMember[]> {
    return this._http.get<IWorkspaceMember[]>(`${this.API_ENDPOINT}/members`);
  }

  getClients$(): Observable<IWorkspaceClient[]> {
    return this._http.get<IWorkspaceClient[]>(`${this.API_ENDPOINT}/clients`);
  }

  getGuidesByWorkspaceId$(workspaceId: number): Observable<IServiceGuide[]> {
    return this._http.get<IServiceGuide[]>(`${PUBLIC_WORKSPACE_ENDPOINT}/${workspaceId}/guides`);
  }

  getInfo$(workspaceId: number): Observable<IWorkspaceSettingsData> {
    return this._http.get<IWorkspaceSettingsData>(`${PUBLIC_WORKSPACE_ENDPOINT}/${workspaceId}/info`);
  }
}
